<template>
    <div style="min-width:75%;">
        <h1 class='text-secondary mb-5'>{{ section.name }}</h1>

        <p>
            <ul>
                <li class="mb-5" v-for="course in datas.courses">
                    <div class='row'>
                        <div class="col-sm-8">
                            <span class="text-secondary">{{ course.name }}</span>
                        </div>
                        <div class="col-sm-2 col-6 text-start">
                            <span class="text-danger">{{ course.level }}</span>
                        </div>
                        <div class="col-sm-2 col-6 text-end">
                            <span class="text-success">{{ course.from }} - {{ course.to }}</span>
                        </div>
                    </div>

                    <div class='text-primary options'>
                        {{ course.option }}
                        <br v-if="course.option != null && course.school != null" />
                        {{ course.school }}
                    </div>
                </li>
            </ul>
        </p>



    </div>
</template>

<script>
export default {
    // name
    name: 'SectionCourses',
    // components
    components: {
    },
    // props
    props : {
        section : { type : Object, required : true }
    },
    // data
    data : function() {
        return {

        }
    },
    mounted : function() {

    },
    methods : {

    },
    computed : {
    }
}
</script>

<style scoped>
.options {
    font-size:1rem;
    line-height:1.5rem;
}
</style>
