// Vue
import { createApp } from 'vue'
import App from './App.vue'

// Vue router
import router from './router'

// https://www.npmjs.com/package/bootstrap#quick-start
// import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap";
window.bootstrap = require("bootstrap")

// icons
import 'bootstrap-icons/font/bootstrap-icons.css'

// https://github.com/quelchx/vue-writer#installation
import VueWriter from "vue-writer";

// dayjs
import dayjs from 'dayjs';
dayjs.extend(require('dayjs/plugin/relativeTime'));
import 'dayjs/locale/fr';
dayjs.locale('fr');



// custom style
import '@/assets/lumen.bootstrap.css';
import '@/assets/style.css';

// creating app
let app = createApp(App);

// add variables
try {
    app.config.globalProperties.datas = require("/datas/tfrichet.json");
} catch (e) { console.log(e); }


// add dayjs
app.config.globalProperties.$dayjs = dayjs;

// mout app
app.use(router).use(VueWriter).mount('#app');
