<template>
    <div>
        <h1 class='text-secondary mb-5'>{{ section.name }}</h1>

        <template v-for="skillType in datas.skills">
            <h2 class="text-success mt-5 mb-3">{{ skillType.title }}</h2>

            <p class="">
                <ul>
                    <li v-for="skillCategory in skillType.skills" >
                        <b :class="'text-'+skillCategory.class">{{ skillCategory.name }} : </b>
                        {{ skillCategory.skills.join(', ') }}
                    </li>
                </ul>
            </p>
        </template>
    </div>
</template>

<script>
export default {
    // name
    name: 'SectionSkills',
    // components
    components: {
    },
    // props
    props : {
        section : { type : Object, required : true }
    },
    // data
    data : function() {
        return {

        }
    },
    mounted : function() {

    },
    methods : {

    },
    computed : {
    }
}
</script>

<style scoped>
ul {
    line-height: 3rem;
}

</style>
