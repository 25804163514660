<template>
    <div>
        <h1 class='text-secondary'>{{ section.name }}</h1>

        <h2 class="text-success mt-5 mb-3">Langues</h2>

        <p>
            <ul>
                <li v-for="lang in datas.personal.languages">
                    <span class='text-secondary'>{{ lang.lang }} : </span>
                    <span class='text-primary'>{{ lang.level }}</span>
                </li>
            </ul>
        </p>

        <h2 class="text-success mt-5 mb-3">Centres d'intérêt</h2>

        <p v-for="interest in datas.personal.interests">
            <i class="text-secondary bi me-2" :class="interest.icon" ></i>
            <span class='text-primary'>{{ interest.name }}</span>
        </p>

    </div>
</template>

<script>
export default {
    // name
    name: 'SectionPersonal',
    // components
    components: {
    },
    // props
    props : {
        section : { type : Object, required : true }
    },
    // data
    data : function() {
        return {

        }
    },
    mounted : function() {

    },
    methods : {

    },
    computed : {
    }
}
</script>

<style scoped>
</style>
