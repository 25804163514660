<template>
    <div style="min-width:75%;">
        <div class="d-flex flex-column flex-lg-row">
            <h1 class='flex-grow-1 text-secondary'>{{ section.name }} <button class="btn btn-danger ms-5 shadow" @click="detail = !detail">{{ detail ? "Masquer" : "Voir"}} les détails</button></h1>
        </div>

        <div class="py-3 job w-100" :class="{ detailed : detail }" v-for="job in datas.career">
            <div class="d-flex flex-column flex-lg-row organization">
                <div class="pb-3 pe-3 logo text-center">
                    <img :src="job.organization.logo" />
                </div>
                <div class="flex-grow-1">
                    <div class="name">
                        <h3 class='text-secondary'>{{ job.organization.name }}</h3>
                        <span class='text-primary'>
                            {{ job.organization.type != null ? [job.organization.type, job.organization.location].join(' - ') : job.organization.location }}
                        </span>
                    </div>

                    <ul class="missions mb-3">
                        <li v-for="mission in job.missions">
                            <div :class="{'mb-4': detail, 'mb-0': !detail }">

                                <div class="row">
                                    <div class='col-xl-4 col-sm-6 col-12 text-secondary'>
                                        {{ mission.label }}
                                    </div>

                                    <div class="col-xl-4 col-sm-4 col-12 text-success">
                                        {{ showPeriod(mission.from, mission.to) }}
                                    </div>

                                    <div class='col-sm-2 col-12 text-info'>
                                        {{ mission.contract }}
                                    </div>

                                </div>

                                <div class="detail" v-if="detail">
                                    <ul class="text-secondary">
                                        <li v-for="task in mission.tasks">{{ task }}</li>
                                    </ul>

                                    <div v-if="mission.stack != null" class='ms-2 mt-2 text-warning'>
                                        <i class="bi bi-stack me-1"></i> {{ mission.stack.join(', ') }}
                                    </div>
                                </div>


                            </div>
                        </li>
                    </ul>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    // name
    name: 'SectionSkills',
    // components
    components: {
    },
    // props
    props : {
        section : { type : Object, required : true }
    },
    // data
    data : function() {
        return {
            detail : false,
        }
    },
    mounted : function() {

    },
    methods : {
        showPeriod : function(from, to) {
            let fromDate = this.$dayjs([from[0], from[1], 1]);
            let toDate = to == null ? null : this.$dayjs([to[0], to[1], 1]);

            if (toDate != null)
            {
                return fromDate.format('MM/YYYY') + " - " + toDate.format('MM/YYYY') + " · " +fromDate.from(toDate, true);
            }
            else
            {
                return "Depuis " + fromDate.format('MMMM YYYY') + " · " + fromDate.fromNow(true);
            }
        }
    },
    computed : {
    }
}
</script>

<style scoped>
.detailed:not(:last-child) {
    border-bottom:1px solid #DDD;
}

.logo img {
    width : 48px;
    height : 48px;
}

.organization .name h3 {
    margin-bottom : 0px;
}

h3 {
    font-size:1.5rem;
}
</style>
