<template>
    <div>
        <div class="w-100 text-sm-start text-center">
            <img src="@/assets/images/avatar.jpg" :alt="datas.about.title" class="d-sm-none img-thumbnail rounded-circle w-75 shadow" style="max-width:200px;" >

            <h1 class='text-secondary'>{{ datas.about.name }}</h1>

            <span class='text-primary'>
                {{ new Date().getFullYear() - datas.about.informations.birth }} ans, {{ datas.about.informations.location }}<br />
            </span>
        </div>


        <div class="my-5">
            <h2 class="text-success jetbrainsmono writer-wrapper">
                <span class="text-secondary">></span> {{ datas.about.functions.prefix }}
                <span class=''>
                    <vue-writer style="display:inline;text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);" :array="datas.about.functions.texts" :eraseSpeed="20" :typeSpeed="30" :delay="1000" /><b class='blink_me'>_</b>
                </span>
            </h2>
        </div>

        <div class="d-flex">
            <div class="pt-2">
                <i class="bi bi-chat-left-quote-fill me-3"></i>
            </div>
            <div class="">
                <p style="line-height: 2;">
                    {{ datas.about.introduction1 }}<br />
                    {{ datas.about.introduction2 }}<br />
                    {{ datas.about.introduction3 }}<br />
                </p>
            </div>
        </div>

        <div class="d-flex text-primary">
            <div class="pt-1">
                <i class="bi bi-stack me-3"></i>
            </div>
            <div class="">
                <p>
                    <span class="text-primary">{{ datas.about.favorite_stack }}</span>
                </p>
            </div>
        </div>


        <div class='mt-5'>
            <template v-for="link in datas.about.links">
                <template v-if="link.web_label != null">
                    <template v-if="link.is_route">
                        <router-link :to="link.url" class='shadow btn me-4 mb-4 text-capitalize' :class="'btn-'+link.class">
                            <i class="bi me-1" :class="link.icon"></i> {{ link.web_label }}
                        </router-link>
                    </template>
                    <template v-else>
                        <a class='shadow btn me-4 mb-4 text-capitalize' :class="'btn-'+link.class" :href="link.url" target="_blank">
                            <i v-if="link.icon != null" class="bi me-1" :class="link.icon"></i>
<!--                            <span v-if="link.icon_svg != null" v-html="link.icon_svg"></span>-->
                            <svg v-if="link.icon_svg != null" style="width:22px;" data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" v-html="link.icon_svg"></svg>
                            {{ link.web_label }}

                        </a>
                    </template>
                </template>
            </template>
        </div>


    </div>
</template>

<script>
export default {
    // name
    name: 'SectionAbout',
    // components
    components: {
    },
    // props
    props : {
        section : { type : Object, required : true }
    },
    // data
    data : function() {
        return {

        }
    },
    mounted : function() {

    },
    methods : {

    },
    computed : {
    }
}
</script>

<style scoped>
@media only screen and (max-width: 1100px) {
    .writer-wrapper {
        font-size:1.5rem;
        height:2.5rem;
    }
}

.is-typed {

}

.is-typed span.typed {
  color: black;
}

.is-typed span.cursor {
  display: inline-block;
  width: 3px;
  background-color: black;
  animation: blink 1s infinite;
}

.is-typed span.underscore {
  display: inline-flex;
  width: 10px;
  height: 1px;
  align-items:flex-end;
  background-color: black;
  animation: blink 1s infinite;
}

.is-typed span.cursor.typing {
  animation: none;
}

@keyframes blink {
  49% {
    background-color: black;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}


.blink_me {
  animation: blinker 1s steps(1, end) infinite;
}

@keyframes blinker {
  50% { opacity: 0; }
}

</style>
