<template>
    <div class="container-fluid overflow-hidden">
        <div class="row vh-100 overflow-auto" @scroll="scrollEvent">
            <div class="col-12 col-sm-4 col-xl-2 px-sm-2 px-0 bg-primary d-flex sticky-top" style="border-right : 4px solid #137ea7;">
                <div class="d-flex flex-sm-column flex-row flex-grow-1 align-items-center text-white">

                    <template v-if="!verticalBreakpoint">
                        <img src="@/assets/images/avatar.jpg" :alt="datas.about.title" class="img-thumbnail rounded-circle w-75 d-none d-sm-block shadow" style="max-width:200px;" :style="{ marginTop : menuMarginTop+'px'}" >
                    </template>

                    <a href="/" class="d-flex align-items-center text-info text-decoration-none mt-sm-3 mb-sm-5">
                        <b class="d-none d-sm-inline"><span class="fs-5">{{ datas.about.name }}</span></b>
                    </a>

                    <ul class="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start" id="menu">

                        <template v-for="(section, sectionIndex) in datas.sections" :key="'sidebar_section_' + sectionIndex">
                            <li class="nav-item w-100 px-sm-4 px-1 rounded"  :class="{ visible : section.visible }">
                                <a :href="'#' + section.id" @click.prevent="goToSection(section.id)" class="nav-link px-sm-0 px-2 text-white" >
                                    <i class="text-black me-1 fs-5" :class="section.icon"></i>
                                    <span class="ms-1 d-none d-sm-inline">{{ section.name }}</span>
                                </a>
                            </li>
                        </template>

                    </ul>
                </div>
            </div>
            <div class="col d-flex flex-column h-sm-100">
                <main class="row overflow-auto bg-secondary">
                    <div class="col p-0">

                        <div :id="section.id" v-for="(section, sectionIndex) in datas.sections" :key="'content_section_' + sectionIndex" ref="content_sections" class='content_section p-sm-5 p-3 d-flex align-items-center'>

                            <template v-if="section.component != null">
                                <component :is="section.component" :section="section"></component>
                            </template>

                        </div>

                    </div>
                </main>
                <footer class="row bg-secondary p-3 mt-auto" style='border-top: 2px solid #d8d8d8;'>
                    <div class="col-xl-5 col-lg-12 col-12 text-start">
                        <template v-for="link in datas.about.links">
                            <template v-if="link.web_label != null">
                                <template v-if="link.is_route">
                                    <router-link :to="link.url" class='link-primary text-decoration-none me-5'>
                                        <i class="bi me-1" :class="link.icon"></i> {{ link.web_label }}
                                    </router-link>
                                </template>
                                <template v-else>
                                    <a class='link-primary text-decoration-none me-5'  :href="link.url" target="_blank">
                                        <i class="bi me-1" :class="link.icon"></i> {{ link.web_label }}
                                    </a>
                                </template>
                            </template>
                        </template>
                    </div>
                    <div class="col-xl-5 col-lg-8 col-12 text-start">
                        <span style="color:#AAA;">{{ datas.credits }}</span>
                    </div>
                    <div class="col-xl-2 col-lg-4 col-12 text-end">
                        <a class="text-dark text-decoration-none fw-bold" :href="datas.about.url_web">{{ datas.about.name }}</a>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import SectionAbout from "./SectionAbout.vue"
import SectionSkills from "./SectionSkills.vue"
import SectionCareer from "./SectionCareer.vue"
import SectionCourses from "./SectionCourses.vue"
import SectionPersonal from "./SectionPersonal.vue"


export default {
    // name
    name: 'Layout',
    // components
    components: {
        SectionAbout, SectionSkills, SectionCareer, SectionCourses, SectionPersonal
    },
    // data
    data : function() {
        return {
            menuMarginTop : window.innerHeight * 0.15,
            verticalBreakpoint : false,
        }
    },
    mounted : function() {
        window.addEventListener('resize', this.resizeEvent);

        this.scrollEvent();
    },
    methods : {
        scrollEvent : function (target = null) {
            let scroll = target == null ? 0 : target.srcElement.scrollTop;

            this.menuMarginTop = scroll + window.innerHeight * 0.15;

            this.datas.sections.forEach((section, sectionIndex) => {
                section.position = this.$refs.content_sections[sectionIndex].offsetTop;
                section.height = this.$refs.content_sections[sectionIndex].clientHeight;
                section.visible =
                (
                    section.position < (scroll + window.innerHeight) && section.position + section.height >= (scroll)
                    &&
                    (this.datas.sections[sectionIndex -1] == undefined || this.datas.sections[sectionIndex -1].visible == false)
                );
            });
        },
        resizeEvent : function () {
            this.verticalBreakpoint =  window.innerHeight < 500;
        },
        goToSection(sectionId) {
            document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
        }
    },
    computed : {
    }
}
</script>

<style scoped>


.nav-item.visible {
    background-color: #137ea7;
}

.nav-link:hover {
    color:var(--bs-info) !important;
}

.content_section {
    background-color:white;
    min-height:100vh;
    border-bottom:1px solid #CCC;
    width:100%;
}
</style>
