import { createRouter, createWebHistory } from "vue-router";
import Layout from '../components/Layout.vue'
import Sheet from '../components/Sheet.vue'

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: Layout
    },
    {
        path: '/sheet',
        name: 'Sheet',
        component: Sheet
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router
