<template>
    <div class="w-100 text-center p-5 d-print-none">
        <button class="btn btn-primary" @click="askPrint">
            <i class="bi bi-filetype-pdf me-1"></i>
            Enregistrer en PDF ou imprimer
        </button>
    </div>

    <div class="A4">
        <img class="float-end" style='width:2.6cm;height:2.6cm;' src="@/assets/images/avatar.jpg" />
        <div class="float-end text-end me-3">
            <h1 class="text-primary">{{ datas.about.functions.main }}</h1>
        </div>
        <h1>
            <span class='text-secondary'>{{ datas.about.name }}</span>
        </h1>
        <div class='text-primary'>
            {{ new Date().getFullYear() - datas.about.informations.birth }} ans, {{ datas.about.informations.location }}
        </div>

        <template v-for="link in datas.about.links">
            <template v-if="link.sheet_label != null">
                <a class='text-decoration-none me-5' :class="'text-'+link.class" :href="link.url" target="_blank">
                    <i class="bi me-1" :class="link.icon"></i> {{ link.sheet_label }}
                </a>
            </template>
        </template>

        <h2 class='text-secondary'>Compétences</h2>

        <ul>
            <template v-for="skillType in datas.skills">
                <li v-for="skillCategory in skillType.skills" >
                    <span class='text-primary'>{{ skillCategory.name }} : </span>
                    <span class='text-secondary'>{{ skillCategory.skills.join(', ') }}</span>
                </li>
            </template>
        </ul>

        <h2 class='text-secondary'>Expériences</h2>
        <div class="py-1 job w-100" v-for="job in datas.career">
            <div class="d-flex flex-row organization">
                <div class="pb-3 pe-3 logo">
                    <img :src="job.organization.logo" />
                </div>
                <div class="flex-grow-1">
                    <div class="name">
                        <div class='text-secondary'>{{ job.organization.name }}</div>
                        <span class='text-primary'>
                            {{ job.organization.type != null ? [job.organization.type, job.organization.location].join(' - ') : job.organization.location }}
                        </span>
                    </div>

                    <ul class="missions mb-1">
                        <li v-for="mission in job.missions">
                            <div class="row">
                                <div class='col-6 text-secondary'>
                                    {{ mission.label }}
                                </div>

                                <div class="col-4 text-success text-end">
                                    {{ showPeriod(mission.from, mission.to) }}
                                </div>

                                <div class='col-2 text-end text-info'>
                                    {{ mission.contract }}
                                </div>

                            </div>
                        </li>
                    </ul>

                </div>
            </div>
        </div>

        <h2 class='text-secondary'>Formations</h2>
        <ul>
            <li class="" v-for="course in datas.courses">
                <div class='row'>
                    <div class="col-8 text-secondary">
                        <span class="">{{ course.name }}</span>
                    </div>
                    <div class="col-2 text-end text-success">
                        <span class="">{{ course.level }}</span>
                    </div>
                    <div class="col-2 text-end text-info">
                        <span class="">{{ course.from }} - {{ course.to }}</span>
                    </div>
                </div>
            </li>
        </ul>


        <h2 class='text-secondary'>Notes personnelles</h2>
        <ul>
            <li v-for="lang in datas.personal.languages">
                <span class='text-primary'>{{ lang.lang }} : </span>
                <span class='text-secondary'>{{ lang.level }}</span>
            </li>
            <li>
                <span class='text-primary'>Centres d'intérêt :</span>
                <span class='text-secondary'>
                    <template v-for="(interest, interestIndex) in datas.personal.interests">
                        {{ interestIndex != 0 ? ', ' : '' }}
                        {{ interest.name }}
                    </template>
                </span>
            </li>
        </ul>

        <div class='float-end text-secondary' style="font-size:0.8em;margin-top:-20px;">
            Généré le {{ $dayjs().format('DD/MM/YYYY') }} depuis <span class='text-decoration-none text-success'>{{ datas.about.url_sheet }}</span>.
        </div>

    </div>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default {
    name: 'Download',
    mounted : function() {
    },
    methods : {
        showPeriod : function(from, to) {
            let fromDate = this.$dayjs([from[0], from[1], 1]);
            let toDate = to == null ? null : this.$dayjs([to[0], to[1], 1]);

            if (toDate != null)
            {
                return fromDate.format('MM/YYYY') + " - " + toDate.format('MM/YYYY') + " · " +fromDate.from(toDate, true);
            }
            else
            {
                return "Depuis " + fromDate.format('MMMM YYYY') + " · " + fromDate.fromNow(true);
            }
        },
        askPrint : function() {
            window.print();
        }
    },
    computed : {
    }
}
</script>

<style scoped>
.A4
{
    font-size:0.38cm;
	line-height:0.50cm;

    background: white;
	display: block;
	margin: 0 auto;
	margin-top: 0.5cm;
	margin-bottom: 0.5cm;
	box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
	width: 21cm;
	height: 29.6cm;
	padding:0.5cm;

    break-after: page;
}

h1 {
    font-weight: normal;
    font-size:1.3em !important;
	margin:0px;
	margin-bottom:0.1cm;
}

h2 {
	font-size:1.2em !important;
	margin:0px;
	margin-top:0.6cm;
	padding-bottom:0.15cm;
	margin-bottom:0.15cm;
	border-bottom: 1px solid #EEE;
}

.logo img {
    width : 1cm;
    height : 1cm;
}

@media print
{
	body, .A4
	{
		margin: 0;
		box-shadow: 0;
		size: auto;
		margin: 0mm;
		-webkit-print-color-adjust: exact;
	}

	a[href]:after {
		content: none !important;
	}

	b
	{
		font-weight:700 !important;
	}

	* {
		page-break-after:avoid;
	}
}
@page
{
	size: auto;
	margin: 0mm;
}

@media only screen and (max-width: 1100px) {
    .A4
    {
        overflow:scroll;
        margin:50px !important;
    }
}
</style>
