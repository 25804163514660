<template>
    <router-view />
</template>

<script>
export default {
    // name
    name: 'App',
    // components
    components: {

    },
    // data
    data : function() {
        return {
            errors : [],
        }
    },
}
</script>

<style scoped>
</style>
